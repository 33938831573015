import React from "react"
import ArrowRight from "../../icons/arrow-right"
import { LefrArrow } from "../../icons"
import * as Styled from "./index.style"

const Navigation = ({ handleClickPrev, handleClicknext }) => {
  return (
    <Styled.ButtonWrapper>
      <Styled.Button onClick={handleClickPrev}>
        <LefrArrow />
      </Styled.Button>
      <Styled.Button onClick={handleClicknext}>
        <ArrowRight />
      </Styled.Button>
    </Styled.ButtonWrapper>
  )
}
export default Navigation
