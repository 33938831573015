import React, { useRef, useEffect } from "react"
import Navigation from "./navigation"
import * as Styled from "./index.style"
import useWindowSize from "../../hooks/use-window-size"
import { navigate } from "gatsby"

const LightBox = ({
  images,
  setWidth,
  xPosition,
  handleClickPrev,
  handleClicknext,
  setXPosition
}) => {
  const slideRef = useRef()
  const windowSize = useWindowSize()

  useEffect(() => {
    if (slideRef.current) {
      const width = slideRef.current.clientWidth
      setWidth(width)
    }
    return () => setXPosition(0)
  }, [setWidth, windowSize.width])

  const imagesLength = images.length

  return (
    <Styled.Wrapper>
      <Styled.CloseButton onClick={() => navigate(-1)}>x</Styled.CloseButton>
      <Styled.Slide xPosition={xPosition} ref={slideRef}>
        {images.map(elem => (
          <Styled.ImgWrapper key={elem.node.id}>
            <Styled.ImgContainer>
              <Styled.StyledImg
                aspectRatio={elem.node.childImageSharp.fluid.aspectRatio}
                fluid={elem.node.childImageSharp.fluid}
                alt={elem.node.name}
              />
            </Styled.ImgContainer>
          </Styled.ImgWrapper>
        ))}
      </Styled.Slide>
      {imagesLength > 1 && (
        <Navigation
          handleClickPrev={handleClickPrev}
          handleClicknext={handleClicknext}
        />
      )}
    </Styled.Wrapper>
  )
}
export default LightBox
