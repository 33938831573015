import styled from 'styled-components'
import Img from "gatsby-image"
import { mediaMax } from '../../helpers/media-queries'

export const Wrapper = styled.div`
  background: var(--primary-background-color);
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`

export const ContentWrapper = styled.div`
  min-width: 100vw !important;
  padding: 24px;
  position: relative;
`

export const StyledImg = styled(Img)`
  object-fit: cover;
  max-width: ${({ aspectRatio }) => `${80 * aspectRatio}vh`};
  max-height: ${({ aspectRatio }) => `${80 * aspectRatio}vw`};
  margin: 0 auto;
`

export const ImgContainer = styled.div`
  width: calc(100vw - 32px);
  margin: 0 16px;
  position: relative;
`

export const CloseButton = styled.span`
  position: absolute;
  width: 20px;
  z-index: 2;
  height: 20px;
  cursor: pointer;
  top: 58px;
  color: #EFE8DC;
  background: #47341E;
  border-radius: 100%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-family: monospace;
  padding-bottom: 2px;
`

export const ArrowsWrapper = styled.div`
  display:flex;
  gap: 8px;
  width: 100%;
  padding: 0px 16px;
  justify-content: flex-end;

  & > svg {
    cursor: pointer;
  }
`

export const Slide = styled.div`
  width: 100vw;
  height: calc(100svh - 70px);
  display: flex;
  align-items: center;
  transition: transform 0.6s ease-in-out;
  transform: ${props => `translateX(${props.xPosition}px)`};
  ${mediaMax('phoneXL')} {
    max-height: calc(100svh - 100px);
    height: 100%;
  }
`

export const ImgWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 70px;
`

export const ButtonWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`

export const Button = styled.div`
  cursor: pointer;
`
